.lightbox {
    display: block;
    position: relative;
    z-index: 1;

    &__border {
        @include layer_full;
        z-index: -1;

        &--outer {
            $bevel: 17px;
            $spacing: 0px;
            $threshold: 1;
            background: $base-normal;
            @include border_bevel($bevel, $spacing, $threshold);
        }
        &--spacer {
            $bevel: 17px;
            $spacing: 1px;
            $threshold: 1.1;
            background: $gray-darkest;
            @include border_bevel($bevel, $spacing, $threshold);
        }
        &--inner {
            $bevel: 17px;
            $spacing: 6px;
            $threshold: 1.4;
            background: $base-normal;
            @include border_bevel($bevel, $spacing, $threshold);
        }
    }

    &__background {
        z-index: -1;
        @include layer_full;

        $bevel: 17px;
        $spacing: 7px;
        $threshold: 1.4;
        @include border_bevel($bevel, $spacing, $threshold);
        background: $gray-darkest;
    }

    &__content {
        padding: 20px;
    }

    &__control {
        height: 40px;
        position: relative;
        &__close {
            display: block;
            position: absolute;
            right: 16px;
            top: 16px;
            svg {
                width: 32px;
                height: 32px;
            }
        }
    }
}
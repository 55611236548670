.article {
    position: relative;
    $block_space: 12px;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 26px;
    color: $black;
    p {
        margin-bottom: $block_space;
    }
    h2 {
       color: $base-normal;
       font-weight: bold; 
       font-size: 20px;
       margin-bottom: $block_space;

       &.headline {
           font-size: 32px;
       }
    }
    h3 {
        color: $base-normal;
        font-weight: bold;
        margin-bottom: $block_space;
    }
    h4 {
        color: $base-normal;
        font-weight: bold;
        margin-bottom: $block_space;
    }
    strong {
        font-weight: bold;
    }
    a {
        color: $white;
        font-style: italic;
        text-decoration: none;;
    }
    ul {
        margin-bottom: $block_space;
        & > li {
            position: relative;
            padding-left: 20px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 6px;
            }
        }
    }

    img {
        max-width: 100%;
        display: block;
        margin: 0 auto $block_space;
    }

    // SPECIAL BUILT - IN

    &__list {
        &__dash {
            & > li {
                &:before {
                    background: $black;
                    width: 8px; 
                    height: 1px;
                    top: 13px;
                }
            }
        }
        &__dot {
            & > li {
                &:before {
                    background: $black;
                    width: 6px; 
                    height: 6px;
                    border-radius: 99px;
                    top: 9px;
                }
            }
        }
        &__check {
            & > li {
                &:before {
                    width: 4px; 
                    height: 8px;
                    background: transparent;
                    border-width: 1px;
                    border-style: solid;
                    border-color: transparent $black $black transparent;
                    transform: rotate(45deg);
                    top: 8px;
                }
            }
        }
    }

    ul {
        & > li {
            &:before {
                background: $black;
                width: 8px; 
                height: 1px;
                top: 13px;
            }
        }
    }



    table {
        max-width: 100%;
        margin: 0 auto $block_space;
        // border-bottom: 1px solid #465580;
        table-layout: fixed;

        tbody { 
            // border-top: 1px solid #465580; 
            // border-bottom: 1px solid #465580;
        } 

        td {
            font-size: 1em;
            padding: 10px;
            border-left: 1px solid rgba(#aaa, .4);
            border-right: 1px solid rgba(#aaa, .4);
            border-top: 1px solid rgba(#aaa, .4);
            border-bottom: 1px solid rgba(#aaa, .4);
            // color: ;
        }

        tr {
            &:nth-child(odd) {
                background: rgba(#ddd, 0);
            }

            &:nth-child(even) {
                background: rgba(#ddd, .1);
            }
        }

        .post table th {
            background-color: #F04E23;
        }

        th {
            // color: #fff;
            font-weight: bolder;
            font-size: 20px;
            color: #F04E23;
            text-align: center;
            padding: 10px 10px;
            border-bottom: 3px solid #F04E23;
        }
    }


    &--detail {
        font-size: 20px;
        line-height: 28px;
        color: $gray-darker;
        text-align: center;

        @include grid-media($mobile, $tablet) {
            font-size: 15px;
            line-height: 24px;
            text-align: left;
        }

        h2 {
            font-size: 28px;
            line-height: 34px;
            color: $gray-darker;

            @include grid-media($mobile, $tablet) {
                width: 100%;
                font-size: 18px;
                line-height: 22px;
                text-align: left;
            }
        }

        h3 {
            font-size: 22px;
            line-height: 26px;
            color: $white;
            padding: 20px 20px;

            @include grid-media($mobile, $tablet) {
                width: 100%;
                font-size: 18px;
                line-height: 22px;
                text-align: left;
            }
        }
    }
}

.game-carousel {
    .game__carousel {
        height: calc(100vh - 80px);

        @include grid-media($mobile, $tablet, $desktop) {

            height: calc(100vh - 54px);
        }

        .heroCarousel {
            height: calc(100vh - 145px - 80px);

            @include grid-media($mobile, $tablet, $desktop) {
    
                height: calc(100vh - 130px - 54px);
            }
            .hero_carousel {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;

                &__background { 
                    display: block;
                    width: 100%;
                    height: 100%; 
                    position: absolute;
                    top: 0;
                    left: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &__content {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 60px;
                }
            }      
        }
    }
}
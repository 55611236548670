.general {
    height: 750px;
    z-index: 2;
    position: relative;
    overflow: hidden;

    @include grid-media($mobile, $tablet) {
        height: 200px;
    }


    &__background {
        height: 750px;
        width: 100%;
        position: absolute;
        transform: perspective(200px) rotateY(0deg);
        transition: all .2s;

        @include grid-media($mobile, $tablet) {
            height: 200px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .article {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__content {
        position: absolute;
        background: $base-normal;
        width: 100%;
        height: 100%;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transform: perspective(200px) rotateY(180deg) scale(0.5, 1);
        transition: all .2s;


        @include grid-media($mobile, $tablet) {
            // height: auto;
            padding: 20px;
        }

    } 

    &:hover, &:focus, &.active {
        @include grid-media($mobile, $tablet) {
            height: 400px;

        }
        .general__background {
            transform: rotateY(180deg);
        }
        .general__content {
            transform: rotateY(0deg);
            opacity: .95;
            transform: scale(1, 1);
        }
    }

}
.title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none; 
    // max-width: calc(100% - 16px * 4);


    // size



    // style 

    &--default {
        .label {
            font-family: $font-primary;
            color: $base-normal;
            font-weight: bold; 
            font-size: 18px;
            line-height: 28px;
        }    
    }

    &--light_line { 
          .label {
              font-family: $font-secondary;
              color: $white;
              font-stretch: condensed;
              font-size: 28px;
              line-height: 34px;
              text-transform: uppercase;
              padding: 18px 90px;
              border-width: 1px;
              border-color: rgba($base-normal, .5) transparent rgba($base-normal, .5) transparent;
              border-style: solid;
              margin: 0 10px;
              text-align: center;

              @include grid-media($mobile) {
                  padding: 18px 20px;
                  font-size: 22px;
                  line-height: 28px;
              }
            
          }
  
          .light_line {
              &__side {
                  display: block;
                  width: 18px;
                  height: 100%;
                  pointer-events: none;
  
                  &--left, &--right {
                      border-width: 3px;
                      border-style: solid;


                      &:before, &:after {
                          content: "";
                          display: block;
                          position: absolute;
                          width: 10px;
                          height: 10px;
                          border-style: solid;
                          border-width: 1px;
                      }
                  }
  
                  &--left {
                        border-color: $base-normal transparent $base-normal $base-normal;
                        border-right-width: 0px;
                      &:before {
                          border-color: rgba($base-normal, .25) transparent transparent rgba($base-normal, .25);
                          top: -10px;
                          left: -10px;
                      }
                      &:after {
                          border-color: transparent transparent rgba($base-normal, .25) rgba($base-normal, .25);
                          bottom: -10px;
                          left: -10px;
                      }
                  }
  
                  &--right {
                    border-color: $base-normal $base-normal $base-normal transparent;
                    border-left-width: 0px;
                        &:before {
                            border-color: rgba($base-normal, .25) rgba($base-normal, .25) transparent transparent;
                            top: -10px;
                            right: -10px;
                        }
                        &:after {
                            border-color: transparent rgba($base-normal, .25) rgba($base-normal, .25) transparent;
                            bottom: -10px;
                            right: -10px;
                        }
                  }
              }
          }
    }

    &--dark_line {
        
          .label {
              font-family: $font-secondary;
              color: $black;
              font-stretch: condensed;
              font-size: 38px;
              line-height: 48px;
              text-transform: uppercase;
              font-weight: bold;
              padding: 14px 90px;
              border-width: 1px;
              border-color: rgba($black, .5) transparent rgba($black, .5) transparent;
              border-style: solid;
              margin: 0 10px;
              text-align: center;

              @include grid-media($mobile) {
                  padding: 18px 20px;
                  font-size: 34px;
                  line-height: 32px;
              }
            
          }
  
          .dark_line {
              &__side {
                  display: block;
                  width: 18px;
                  height: 100%;
                  pointer-events: none;
  
                  &--left, &--right {
                      border-width: 3px;
                      border-style: solid;


                      &:before, &:after {
                          content: "";
                          display: block;
                          position: absolute;
                          width: 10px;
                          height: 10px;
                          border-style: solid;
                          border-width: 1px;
                      }
                  }
  
                  &--left {
                        border-color: $black transparent $black $black;
                        border-right-width: 0px;
                      &:before {
                          border-color: rgba($black, .25) transparent transparent rgba($black, .25);
                          top: -10px;
                          left: -10px;
                      }
                      &:after {
                          border-color: transparent transparent rgba($black, .25) rgba($black, .25);
                          bottom: -10px;
                          left: -10px;
                      }
                  }
  
                  &--right {
                    border-color: $black $black $black transparent;
                    border-left-width: 0px;
                    &:before {
                        border-color: rgba($black, .25) rgba($black, .25) transparent transparent;
                        top: -10px;
                        right: -10px;
                    }
                      &:after {
                          border-color: transparent rgba($black, .25) rgba($black, .25) transparent;
                          bottom: -10px;
                          right: -10px;
                      }
                  }
              }
          }
    }
}

.title {

    &--single-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 0 16px;

        .label {
            font-weight: bold;
            font-size: 20px;
            line-height: 25px;
            color: $gray-darker;
        }

        &:after {
            content: "";
            display: block;
            width: 110%;
            height: 4px;
            border-radius: 10px;
            background: $base-normal;

            position: absolute;
            bottom: 0;
            left: -5%;
        }
    }

    &--tabs {

        display: block;

        // justify-content: flex-start;

        border-bottom: 1px solid rgba(#000, .1);

        @include grid-media($mobile) {
            overflow: auto;
        }

        ul {
            display: flex;
            @include grid-media($mobile) {
                width: max-content;
            }
            
            li {
                display: block;
                float: left;
                margin-right: 24px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    height: 50px;
                    padding: 0 16px;
                    text-decoration: none;
            
                    .label {
                        font-size: 20px;
                        line-height: 25px;
                        color: $gray-lighter;
                        transition: color .2s;

                        &:hover {
                            color: $black;
                        }
                    }
                }


                &.active {
                    a {
                        @extend .title--single-tab;
                    }
                }
            }
        }
    }

}
.login {
    .popup__content {
        width: 530px;
        max-width: calc(100% - 24px);
        max-height: 80vh;
        border: 1px solid #fff;
        border-radius: 12px;
        overflow: hidden;
    }

    &__background {
        position: absolute;
        width:100%;
        height: 100%;
        background: #FFF;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: .8;
        }

    }
 
    &__title {
        height: 60px;
        position: relative;
        background: #fff;
        // border-bottom: 1px solid #424242;

        color: $base-normal;
        font-weight: bold;
        line-height: 28px;
        font-size: 18px;
        text-transform: uppercase;

        display: flex;
        align-items: center;
        justify-content: center;

        ul {
            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }

    }

    &__form {
        width: 100%;
        max-height: calc(80vh - 40px);
        // padding: 60px 45px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;

        @include grid-media($mobile) {
            // padding: 10px 10px;
        }

        &__team {
            padding: 30px 45px;
            @include grid-media($mobile) {
                padding: 10px 10px;
            }
        }

        .forgotPass {
            font-size: 16px;
            line-height: 20px;
            text-decoration: underline;
            color: rgba($gray-lighter, 1);

            &:hover { 
                color: rgba($gray-normal, 1);
            }
        }

        .oryoucan {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            color: rgba($gray-lighter, 1);

            &:before, &:after {
                content: "";
                display: block;
                width: 10%;
                height: 1px; 
                background: $gray-lightest;
            }
        }

        .zme_wg_rowlogin {
            li {
                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }
        }
    }

    
}
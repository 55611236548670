.header {
    position: relative;
    height: 80px;
    background: #333;

    @include grid-media($mobile, $tablet, $desktophd) {
        height: 54px;
    }

    &-side {
        height: 0;
    }

    &__background {
        width: 100%;
        height: auto;
        img {
            display: block;
            width: 100%;
            height: 100%;
            min-height: 160px;
            object-fit: cover;
        }
    }


    .logo {
        display: block;
        width: 181px;
        height: 53px;
        z-index: 2;
        margin-right: 20px;
        @include grid-media($mobile) {
            margin: 0 auto;
        }
        @include grid-media($desktophd) {
            width: 126px;
            height: 37px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }


    .logo-vertical {
        display: block;
        width: 95px;
        height: 86px;
        z-index: 2;
        margin: 0 auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.tournament {
    display: flex;
    flex-wrap: wrap;

    &__single {
        display: block;
        position: relative;
        width: 1200px;
        max-width: calc(100% - 24px);
        padding: 15px;
        // background: $gray-darkest;
        // margin: 0 15px;


        &__thumbnail {
            display: block;
            position: relative;
            width: 100%;
            height: auto;


            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }
        &__title {
            font-family: $font-secondary;
            color: rgba($base-normal, 1);
            font-size: 30px;
            font-weight: bold;
            font-stretch: condensed;
            line-height: 34px;

            @include grid-media($mobile) {
                margin-top: 12px;

                font-size: 24px;
                line-height: 30px;
            }
        }
        &__detail {
            margin-top: 10px;
            color: rgba($white, 1);
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 4px;
            font-weight: 300;

            @include grid-media($mobile) {
                font-size: 16px;
                line-height: 20px;
            }
            ul {
                li {
                    display: flex;
                    justify-content: start;
                    margin-top: 5px;
                    .label {
                        display: block;
                        width: 100px;
                    }
                    .value {
                        display: block;
                        width: calc(100% - 100px);
                        &.prizepool {
                            font-weight: bold;
                            font-size: 22px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
        &__shortcontent {
            margin-top: 10px;
            margin-bottom: 20px;
            color: rgba($white, 1);
            font-size: 18px;
            line-height: 22px;
            font-weight: 300;

            @include grid-media($mobile) {
                font-size: 16px;
                line-height: 20px;
            }
        }
        &__link {
            display: inline-block;
        }

        .swiper-container-tournament {
            .swiper-wrapper {
                transition-duration: .5s;
                transition-timing-function: $ani-easeInCubic;
            }
            .swiper-button {
                &-next, &-prev {
                    &-tournament {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;

                        background: rgba(#fff, 1);

                        &:before, &:after {
                            display: none;
                        }

                        .icon {
                            svg {
                                transform-origin: center center;
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                }
                &-next {
                    &-tournament {
                        right: 0;
                    }
                }
                &-prev {
                    &-tournament {
                        left: 0;
                        .icon {
                            svg {
                                transform: scaleX(-1);
                            }
                        }
                    }
                }
            }
        }

    }
}
.tournament__list {
    position: relative;
}

.swiper-container {
    &-tournamentList {

        .swiper-pagination {
            position: absolute;
            right: 0;
            left: auto !important;
            bottom: 30px !important;
            width: 50% !important;
            display: flex;
            justify-content: flex-start;
            padding-left: 16px;

            @include grid-media($mobile) {
                position: static;
                justify-content: center;
                width: 100% !important;
                padding: 10px 0;
            }
            &-bullet {
                background: transparent;
                opacity: 1;
                width: 60px;
                height: 60px;
                padding: 2px;
                border-radius: 0;
                @include grid-media($mobile) {
                    width: 32px;
                    height: 32px;
                }
                &-active {
                    background: rgba($gray-darker, .45);
                }
                img {
                    display: block;
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    margin: 0 auto;
                }
            }

        }
    }
}
$content_bg: #fff;
$content_color: #111;
$content_bg_reverse: rgba(0,0,0,0.5);
$content_color_reverse: #fff;
$content_borderColor: #ccc;
$content_borderColor_hover: #bbb;
$content_bg_hover: #f8f8f8;

$content_borderColor_danger: #aa1111;
$content_bg_danger: #ffeeee;
$content_bg_hover_danger: #ffdddd;

$content_bg_active: #e6f7ff;
$content_color_active: #111;

 

// CONFIG
 
$number_of_round : 5;

@mixin template_modern {
	$def_matchwidth : 200px;
	$def_roundspace : 40px; 
	.round, .round__loser, .round__roundrobin {
		float: left;
		position: relative;
		width: $def_matchwidth;
		margin-right: $def_roundspace;

		//---- TITLES

		&__title {
			width: $def_matchwidth;
			// padding: 3px 10px;
			// background: #ddd;
			box-sizing: border-box;
			// margin: 10px;

			font-size: 18px;
			color: $white;
			font-weight:bold;
			text-transform: uppercase;
			text-align: center;
			line-height: 60px;
			opacity: 0;
			&:before {
				z-index: -1;
				content: "";
				display: block;
				position: absolute;
				top: 0;
				width: 100%;
				height: 60px;
			}

			&.active {
				background: #94590b;
				&:before {
					background: #a7ebf9;
				}
			}

		}
		&__round_1__title, &__loser_round_1__title {
			&:first-child { 
				&:before { 
				}
			}
		}


		
		// Using for make sprite title for each round
		@for $i from 1 through $number_of_round {
			&__round_#{$i}__title {
				
			}
		}
		
		//---- MATCHES

		&__matches {
			position: relative;
			&__match {
				&__inner {
					box-sizing: border-box;

				}
			}
			.match, .match__loser, .match__roundrobin {
				width: 100%;
				height: 91px;
				margin: 10px;
				position: relative;
				&__inner {
					width: $def_matchwidth;
					height: 85px;
					// background: #fff;
					position: relative; 
					border: none;
					&__info {
						display: block;
						height: 25px;
						padding: 10px 10px;
						width: 200px;
						box-sizing: border-box;
						overflow: hidden;
						.info {
							&__text {
								color: #949494;
								text-align: left;
								font-style: italic;
								font-weight: 200;
								&--date {
									display: block;
									font-size: 10px;
									line-height: 14px;
								}
							}
						}
					}

					&__competitors {
						display: inline-block;
						float: left;
						width: 200px;
						height: 60px;
						.competitors__team {
							position: relative;
							height: 30px;
							// border-radius: 2px;
							// border: 1px solid #f4f4f4;
							background: #282828;
							color: $white;
							margin: 1px;
							overflow: hidden;
							// &:first-child {
							// 	border-bottom: 1px dashed #ccc;
							// }
							&__icon {
								float: left;
								display: inline-block;
								// border-radius: 8px 0 0 8px;
								// background: #010813;
								// border-right: 1px solid #212d40;
								// display: none;
								.icon__inner {
									display: block;
									width: 30px;
									height: 30px;
									padding: 5px;
									box-sizing: border-box;
									img {
										max-width: 100%;
										max-height: 100%;
									}
								}
							}
							&__text {
								float: left;
								display: inline-block;
								padding-left: 10px;
								line-height: 30px;
								width: 135px;
								.text__inner {
									
								}	
							}
							&__score {
								float: right;
								// padding: 5px;
								display: inline-block;
								.score__inner {
									display: block;
									width: 30px;
									height: 30px;
									line-height: 30px;
									text-align: center;
									color: #fff;
									border-radius: 0px;
									// background: rgb(78,78,78);
									// background: -moz-linear-gradient(135deg, rgba(78,78,78,1) 0%, rgba(175,175,175,1) 100%);
									// background: -webkit-linear-gradient(135deg, rgba(78,78,78,1) 0%, rgba(175,175,175,1) 100%);
									// background: linear-gradient(135deg, rgba(78,78,78,1) 0%, rgba(175,175,175,1) 100%);
									// filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4e4e4e",endColorstr="#afafaf",GradientType=1);
									
									background: #363636;

									&.winner {
										background: $base-normal;
										color: $black;
									}
								}
							}

							&:hover, &.active {
								background: #303030;

								.competitors__team__icon {
									// background: #fee8b0;
								}
								.competitors__team__score {
									// background: #010813;
									.score__inner {
										// background: #fee8b0;
										// background: -moz-linear-gradient(135deg, #fee8b0 0%, #ecc56c 100%);
										// background: -webkit-linear-gradient(135deg, #fee8b0 0%, #ecc56c 100%);
										// background: linear-gradient(135deg, #fee8b0 0%, #ecc56c 100%);
										// filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fee8b0",endColorstr="#ecc56c",GradientType=1);
									}
								}
							}

							&.winner {
								box-shadow: 0 0 1px 0px rgba($base-normal, 1);
							}
						}

					}



					&--null {
						border: thin solid #e9e9e9;
						height: 91px;
						background: #fff; 
						color: #efefef;
						display: none; 
						
						.match_number {}
						.date {}
						.competitors {
							&__team {
								.team {
									&__inner {
										.score {}
									}
								}
							}
						}
					}
				}

				// Stage status (current)

				&.active {
					.match {
						&__inner {
							&__competitors {
								.competitors__team {
									background: #FFE08F;
									border: 1px solid #FFE08F;
								}
							}
						}
					}
				}
			}
		}

		// Using for make sprite matches for each round
		@for $i from 1 through $number_of_round {
			&__round_#{$i}__matches {

				&__match {

				}

			}
		}

		&:first-child {
			.round__title, .round__loser__title, .round__roundrobin__title {
				opacity: 1;
			}
		}
	}
}

.dndbracket-rounds {
	padding-left: 20px;
	&.modern {
		@include template_modern;	
	}

}

.dndbracket-rounds-loser {
	padding-left: 20px;
	&.modern {
		@include template_modern;	
	}
}



.dndbracket-rounds-roundrobin {
	padding-left: 20px;
	&.modern {
		@include template_modern;	
		.round__roundrobin {
			width: 100%;
			&__title {
				&:before {
					-webkit-clip-path: polygon(94% 5%, 99% 50%, 94% 94%, 0.4% 94%, 1.4% 50%, 0.4% 6%);
					clip-path: polygon(94% 5%, 99% 50%, 94% 94%, 0.4% 94%, 1.4% 50%, 0.4% 6%);
					
				}
			}
			&__matches {
				.match__roundrobin {
					width: auto;
					float: left;
				}
			}
		}
	}
}

.dndbracket-connections {
	position: absolute;
	top: 0;
	z-index: 0;
	&.modern {
		$connection_color: #494949;
		.prev-a {
			position: absolute;
			border-top: 2px solid $connection_color;
			border-right: 2px solid $connection_color;
			border-bottom: none;
			border-left: none;
			border-radius: 0 0x 0 0;
		}
		.prev-b {
			position: absolute;
			border-top: none;
			border-right: 2px solid $connection_color;
			border-bottom: 2px solid $connection_color;
			border-left: none;
			border-radius: 0 0 0px 0;
		}
		.next {
			position: absolute;
			border-top: none;
			border-right: none;
			border-bottom: 2px solid $connection_color;
			border-left: none;
			&:after {
				content: "";
				display: none;
				background: $connection_color;
				width: 12px;
				height: 12px;
				border-radius: 15px;
				top: 100%;
				position: absolute;
				right: -5px;
				margin-top: -5px;
			}
		}

		.prev-a, .prev-b, .next {
			transform: translateY(-34px);
		}
	}
}
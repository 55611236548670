.footer {
    position: relative;
    overflow: hidden;
    width: 100%;
    background: #191919; 
    height: 100px;
    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: bottom center;
        }
    }

    &__content {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 20px 0;
        text-align: center;

        .copyright {
            &__content {
                p {
                    color: rgba($white, .9);
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 200;
                }
            }
        }

    }
}
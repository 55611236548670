.pagination {

    position: relative;

    width: 580px;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);

    &__back, &__next, &__page {
        display: block;
        background: $gray-darker;
        border: 1px solid $gray-normal;
        height: 50px;
        margin: 0 10px;
        .page {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba($gray-lighter, 1);
            text-decoration: none;

            &.active {
                color: $white;
                .label {
                    font-weight: bold;
                }
            }
        }
    }
    &__back, &__next {
        width: 50px;

        .icon {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 10px;
                height: 20px;
                transform-origin: center center;
                path {
                    stroke: $gray-lighter;
                }
            }
        }
    }

    &__back {
        .icon {
            svg {
                transform: scaleX(-1);
            }
        }
    }
    &__page {
        width: 450px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include grid-media($mobile) {
            width: auto;
            a:nth-child(n + 4) {
                display: none;
            }
        }
    }
}
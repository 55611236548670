.game {
    &__icon {
        display: flex;
        justify-self: center;
        align-items: center;
        flex-wrap: wrap;
        height: 145px;
        width: 145px;
        text-decoration: none;
        margin-bottom: 8px;
        img {
            width: 100%;
            height: calc(100% - 45px);
            object-fit: contain;
        }

        .label{
            display: block;
            margin-top: 5px;
            width: 145px;
            height: 30px;
            font-size: 16px;
            text-align: center;
            color: $gray-lighter;
        }



        @include grid-media($mobile, $tablet, $desktophd) {
            width: 130px;
            height: 130px;

            img {
                width: 70px;
                height: 70px;
                margin: 0 auto;
            }

            .label {
                width: 110px;
                margin: 0 auto;
                height: 30px;
                font-size: 14px;
                line-height: 20px;



            }
        }

        &--sub {
            width: 145px;
            height: 145px;
            display: flex;
            justify-self: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 6px 0;
            box-sizing: border-box;
            margin-bottom: 0;
            img {
                width: 70px;
                height: 70px; 
                margin: 0 auto;
            }
            .label{
                display: block;
                margin: 0px auto 0;
                width: 90px;
                height: 40px;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                color: $gray-lighter; 
            }

            @include grid-media($mobile, $tablet, $desktophd) {
                width: 130px;
                height: 130px;
    
                img {
                    width: 50px;
                    height: 50px;
                }
    
                .label {
                    width: 110px;
                    height: 30px;
                    font-size: 12px;
                    line-height: 15px;



                }
            }

        }

        &:hover {
            filter: brightness( 120% );
        }
        
    }
}


.game {
    &__hero {
        width: 100%;
        height: 100%;
        &__background {
            width: 100%;
            height: 100%;
            height: calc(100vh - 80px - 145px);
            @include grid-media($mobile, $tablet, $desktophd) {
                height: calc(100vh - 54px - 130px);
            }
            img {
                width:100%;
                height: 100%;
                object-fit: cover;
                
                @include grid-media($mobile, $tablet) {
                    animation: fullimg 20s infinite;
                }

                @keyframes fullimg {
                    0%, 100% {
                        object-position: 30% 0%;
                    }
                    50% {
                        object-position: 100% 0%;
                    }
                }
            }
        }
        &__content {
            overflow: hidden;
            width: 100%;
            height: calc(100vh - 84px);
            padding: 20px 60px;
            box-sizing: border-box;
            @include grid-media($mobile, $tablet, $desktop) {
                height: calc(100vh - 54px - 90px);
                padding: 20px 30px;
                overflow-y: auto;
                overflow-x: hidden;
            }

        }
        &__pagination {
            z-index: 5;
            position: absolute;
            bottom: 0;
            width: 805px; // 90 * 5 + 40 * 2 + 200
            left: calc(50% - 805px / 2);
            // height: 120px;
            // background: rgba(#111, 1);

            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            padding: 0 40px;

            @include grid-media($mobile, $tablet) {
                width: 100%;
                left: 0;
                height: 100%;
            }

            @include grid-media($desktophd) {

            }

            .swiper-container {
                width: 700px;
                .swiper-wrapper {
                    // transform: none !important;
                }
                .swiper-slide {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    opacity: .4;
                    overflow: hidden;
                    cursor: pointer;

                    &-active, &:hover, &-active:hover {
                        opacity: .4;
                    }

                    &.active {
                        opacity: 1;
                        background: #000;

                        .game__icon {
                            .label {
                                color: #fff;
                                // font-weight: bold;
                            }
                        }

                        &:before {
                            position: absolute;
                            content: "";
                            display: block;
                            width: 100%;
                            height: 100%;
                            bottom: -60%;
                            transform: scaleX(2) scaleY(0.8);
                            opacity: .3;
                            left: 0;
                            background: radial-gradient(circle, rgba(#FFF, .8) 0%, rgba(#FFF,0) 50%);
                        }

                        &:after {
                            position: absolute;
                            content: "";
                            display: block;
                            width: 100%;
                            height: 100%;
                            bottom: -65%;
                            transform: scaleX(1.2) scaleY(0.6);
                            left: 0;
                            background: radial-gradient(circle, rgba(#FFF, .8) 0%, rgba(#FFF,0) 50%);
                        }
                    }

                    // &:hover {
                    //     opacity: 1;
                    // }
                    // &-active, &-active:hover {
                    //     opacity: 1;
                    //     // filter: blur(0px);
                    // }
                }
            }


        }

        .swiper-button {
            &-next, &-prev {
                &-heroPagination, &-heroContent {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 80px;
                    top: calc(50% - 40px / 2);

                    // background: rgba(#fff, .75);
                    // transition: background-color .2s $ani-easeInCubic;

                    @include grid-media($mobile, $tablet) {
                        height: 40px;
                        top: calc(50% - 20px / 2);
                    }

                    &:before, &:after {
                        display: none;
                    }

                    &:hover {
                        // background: rgba(#fff, 1);
                    }

                    .icon {
                        svg {
                            transform-origin: center center;
                            width: 14px;
                            height: 14px;
                            path {
                                stroke: $gray-lighter; 
                            }
                        }
                    }
                }
            }
            &-next {
                &-heroPagination, &-heroContent {
                    right: 0;
                }
            }
            &-prev {
                &-heroPagination, &-heroContent {
                    left: 0;
                    .icon {
                        svg {
                            transform: scaleX(-1);
                        } 
                    }
                }
            }
        }

        .swiper-pagination {
            display: flex;
            position: relative;
            &-bullet {
                background: none;
                width: 90px;
                // height: 120px;
                height: 90px;
                opacity: 1;
                transform: scale(.8) !important;
                transition: left .2s;
                filter: blur(5px);
                @include grid-media($tablet, $mobile) {
                    height: 90px;
                }
                &:hover {
                    filter: blur(3px);
                }
                &-active, &-active:hover {
                    filter: blur(0);
                    transform: scale(1) !important;
                }
            }

            &:before, &:after {
                content: "";
                display: block;
                width: 100px;
                height: 100px;
                @include grid-media($tablet, $mobile) {
                    height: 70px;
                }
                position: absolute;
                pointer-events: none;
                top: 0;
                z-index: 9;
                background: linear-gradient(270deg, rgba(#111,0) 0%, rgba(#111,1) 100%);
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
                transform: scaleX(-1);
            }
        }
    }
}

.game {
    &-banner {
        z-index: 1;
        height: calc(100vh - 80px - 145px);

        @include grid-media($desktophd) {
            height: calc(100vh - 54px - 130px);
        }

        & + .overlay {
            z-index: 2;
        }



        .swiper-wrapper {
            transition-timing-function: $ani-easeInCubic;
           height: calc(100vh - 80px - 145px);
           @include grid-media($mobile, $tablet, $desktophd) {
               height: calc(100vh - 54px - 130px);
           }
        }

    }
    &-content {
        z-index: 3;
        position: absolute;
        top: 0;
        width: 100%;
        height: calc(100vh - 80px - 145px);

        @include grid-media($desktophd) {
            height: calc(100vh - 54px - 130px);
        }

        .swiper-container {
            .swiper-wrapper {
                transition-timing-function: $ani-easeIn;
                .swiper-slide {
                    // opacity: 0;
                    // left: -400px;
                    // transition: all 2s;
                    &-active {
                        // opacity: 1;
                        // transform: translateX(400px);
                    }
                }
            }
        }

    }
    &-icon {
        z-index: 4;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #111;
        // height: 120px;
        height: 90px;
        @include grid-media($tablet, $mobile) {
            height: 90px;
        }

        @include grid-media($desktop) {
            height: 145px;
        }

        @include grid-media($desktophd) {
            height: 130px;
        }
    }
}

.game {
    &__logo {
        height: 80px;
        width: auto;
        @include grid-media($mobile, $tablet) {
            height: 60px;
        }
        @include grid-media($desktop-lg) {
            height: 100px;
        }

        @include grid-media($desktop-xl, $desktop-xxl) {
            height: 140px;
        }
        img {
            height: 100%;
            width: auto;
        }
    }
    &__type {
        display: flex;
        color: rgba($white, 1);
        font-size: 14px;
        line-height: 20px; 

        @include grid-media($desktop-md) {
            font-size: 18px;
            line-height: 26px;
        }

        @include grid-media($desktop-lg) {
            font-size: 20px;
            line-height: 32px;
        }

        @include grid-media($desktop-xl, $desktop-xxl) {
            font-size: 24px;
            line-height: 36px;
        }


        .type {
            display: flex;
            align-items: center;
            position: relative;
            margin-right: 50px;

            @include grid-media($mobile) {
                margin-right: 30px;
            }

            strong {
                margin-left: 4px;
                color: $white;
                font-weight: bold;
            }

            &:after {
                content: "";
                display: inline-block;
                width: 1px;
                height: 22px;
                background: #FFFFFF;
                position: absolute;
                right: -27px;
                top: 5px;
                @include grid-media($mobile, $tablet) {
                    display: block;
                    width: 0;
                    height: 0;
                    position: static;
                }
            }


        }
        .platform {
            display: flex;
            align-items: center;
            .icon {
                vertical-align: middle;
                display: inline-block;
                width: 30px;
                height: 30px;
        
                @include grid-media($desktop-xl, $desktop-xxl) {
                    width: 40px;
                    height: 40px;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    path {
                        fill: $white;
                    }
                }

                &:nth-child(2) {
                    margin-left: -16px;
                }
            }
        }
    }

    &__shortcontent {
        color: rgba(#e8e8e8, 1);
        width: 33%;
        font-size: 14px;
        line-height: 20px; 

        @include grid-media($tablet) {
            width: 50%;
        }

        @include grid-media($mobile) {
            width: 100%;
        }


        @include grid-media($desktop-md) {
            font-size: 18px;
            line-height: 26px;
        }

        @include grid-media($desktop-lg) {
            font-size: 20px;
            line-height: 32px;
        }

        @include grid-media($desktop-xl, $desktop-xxl) {
            font-size: 24px;
            line-height: 36px;
        }

    }

    &__detail, &__playnow, &__download {
        display: flex;
        justify-content: flex-start;
    }
}
// --- GRID (Followed Bootstrap)

$mobile: (media: "(max-width: 767px)"); 
$tablet: (media: "(min-width: 768px) and (max-width: 1279px)"); 
$desktop: (media: "(min-width: 1280px)");  
$desktophd: (media: "(min-width: 1280px) and (max-width: 1439px)");  
$desktopfhd: (media: "(min-width: 1440px)");  

$desktop-md: (media: "(min-width: 1366px) and (max-width: 1440px)");
$desktop-lg: (media: "(min-width: 1441px) and (max-width: 1680px)");
$desktop-xl: (media: "(min-width: 1681px) and (max-width: 2000px)");
$desktop-xxl: (media: "(min-width: 2001px)");

// --- COLORS 

$white: #FFFFFF;
$black: #000000;

$base-normal: #F04E23;
$base-darker: darken($base-normal, 15%);
$base-darkest: darken($base-normal, 30%);
$base-lighter: lighten($base-normal, 15%);
$base-lightest: lighten($base-normal, 30%);

$gray-normal: #424242;
$gray-darker: #282828;
$gray-darkest: #111111;
$gray-lighter: #949494;
$gray-lightest: #C4C4C4;

$gradient1: linear-gradient(180deg, rgba(255, 233, 59, 0.5) 0%, #FFE93B 0.01%, rgba(255, 233, 59, 0) 110.77%);
$gradient2: linear-gradient(90deg, rgba(255, 233, 59, 0) 107.4%, #FFE93B 0.01%, rgba(255, 233, 59, 0.5) 0%);
$gradient3: linear-gradient(-90deg, rgba(255, 233, 59, 0.5) 0%, rgba(#FFE93B, .3) 0.01%, rgba(255, 233, 59, 0) 107.4%);


// FONT FAMILY

$font-primary: Roboto;
$font-secondary: Roboto; 

// ANIMATION TIMING FUNCTION

$ani-ease: cubic-bezier(0.25,0.1,0.25,1);
$ani-easeIn: cubic-bezier(0.42, 0.0, 1.0, 1.0);
$ani-easeOut: cubic-bezier(0.0, 0.0, 0.58, 1.0);
$ani-easeInOut: cubic-bezier(0.42, 0.0, 0.58, 1.0);
$ani-linear: cubic-bezier(0,0,1,1);
$ani-easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ani-easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ani-easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ani-easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ani-easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$ani-easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ani-easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$ani-easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$ani-easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ani-easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ani-easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ani-easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ani-easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$ani-easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ani-easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$ani-easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$ani-easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ani-easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$ani-easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$ani-easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$ani-easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$ani-easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$ani-easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$ani-easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);

// SIZING 

$header_height: 750px;

// BACKUP


// --- COLORS 

// $white: #FFFFFF;
// $black: #111111;

// $base1-normal: #FFE93B;
// $base1-darker: darken($base1-normal, 15%);
// $base1-darkest: darken($base1-normal, 30%);
// $base1-lighter: lighten($base1-normal, 15%);
// $base1-lightest: lighten($base1-normal, 30%);

// $base2-normal: #69B7D9;
// $base2-darker: darken($base2-normal, 15%);
// $base2-darkest: darken($base2-normal, 30%);
// $base2-lighter: lighten($base2-normal, 15%);
// $base2-lightest: lighten($base2-normal, 30%);

// $base3-normal: #FFE27B;
// $base3-darker: darken($base3-normal, 15%);
// $base3-darkest: darken($base3-normal, 30%);
// $base3-lighter: lighten($base3-normal, 15%);
// $base3-lightest: lighten($base3-normal, 30%);

// $gray-normal: #888;
// $gray-darker: darken($gray-normal, 15%);
// $gray-darkest: darken($gray-normal, 30%);
// $gray-lighter: lighten($gray-normal, 15%);
// $gray-lightest: lighten($gray-normal, 30%);
.loading {
    &--img, &--title, &--text, &--paragraph, &--block {
        display: inline-block;
        overflow: hidden;
        position: relative;


        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 50%;
            height: 100%;
            background: rgba(#fff, 1);
            background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff,.5) 50%, rgba(#fff,0) 100%);
            animation: loading infinite 1s;
            top: 0;
            left: -50%;
        }

        @keyframes loading{
            100% {transform: translateX(300%);}
        }

    }
    &--img, &--block {
        width: 100%;
        height: 100%;
        background: #eee;
    }
    &--title {
        width: 200px;
        height: 23px;
        background: #eee;
        &:last-child {
            width: 180px;
        }
    }
    &--text {
        width: 120px;
        height: 20px;
        background: #eee;
    }
    &--paragraph {
        width: 100%;
        height: 20px;
        background: #eee;
        &:last-child {
            width: 80%;
        }
    }
}

.tournament {
    &__single--loading {
        a {
            .meta {
                background: #ccc;
            }

            &:hover {
                .meta {
                    background: #ccc;
                }
            }
        }
    }
}

.error {
    &__single {
        margin-bottom: 24px;
        .meta { 
            display: block;
            padding: 20px 0;
            background: #eee;
        }
    }
}


.new {
    &__single--loading {

        .meta {
            &__time {
                &:before {
                    display: none;
                }
                &:after {
                    display: none;
                }
            }
        }
        &:before {
            display: none;
        }
    }
}


.video {
    &__single--loading { 
        a {
            .meta {
                background: rgba(#111, .1);
            }
        }
    }
} 


.highlight {
    &__single--loading {
        a {
            .meta {
                background: rgba(#111, .1);
            } 
        }
    }
}
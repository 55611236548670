.content {
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 80px);
    @include grid-media($mobile, $tablet, $desktophd) {
        min-height: calc(100vh - 54px);
    }
    &__background {
        width: 100%;
        position: absolute;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        pre {
            position: relative;
            color: $white;
        }
    }

    .cover-banner {
        width: 100%;
        height: 335px;
        

        @include grid-media($mobile, $tablet) {
            height: 100px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.navigation {
    width: 100%;
    pointer-events: all;
    position: absolute;
    z-index: 5;
    height: 80px;
    transition: height .2s $ani-easeOutSine;

    @include grid-media($mobile, $tablet, $desktophd) {
        height: 54px;  
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        position: relative;
        padding: 0 20px;
        @include grid-media($mobile, $tablet) {
            flex-wrap: wrap;
            padding: 0 16px;
            justify-content: space-between;
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: center;


            &:nth-of-type(2) {
                
            }

            @include grid-media($mobile, $tablet, $desktophd) {
                // flex-wrap: wrap;
                // order: 3;
            }

            li {
                position: relative;
                margin:0 10px;
                @include grid-media($mobile, $tablet, $desktophd) {
                    margin: 0 2px;
                }
                a {
                    &:hover, &.active, &:focus {
                        & + ul {
                            transform: scale(1, 1);
                            max-height: 9999px;
                        }
                    }
                }
                ul {
                    display: flex;
                    align-items: center;
                    z-index: 6;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    background: rgba($white, 1);
                    flex-wrap: wrap;
                    transform-origin: top center;
                    transform: scale(.8, 0);
                    transition: transform .2s $ani-easeOutCirc;

                    @include grid-media($mobile) {
                        position: relative;
                        max-height: 0;
                        overflow: hidden;

                    }

                    &:hover {
                        transform: scale(1, 1);
                        max-height: 9999px;
                    }

                    li {
                        width: 100%;
                        margin:0 0px;
                        &:not(:last-child) {
                            border-bottom: 1px solid rgba(#000, .1);
                        }

                    }
                }

            }
        }
    }
    &--zingplay {
        nav {
            background: rgba(#fff, 1);
            box-shadow: 0 3px 30px 0px rgba(#000, .1);
            .hamburger {
                display: none;
            }
            @include grid-media($mobile, $tablet) {
                align-items: flex-start;
                padding: 0px 16px;

                .hamburger {
                    display: block;
                    width: 56px;
                    height: 56px;

                }
            }
            &:before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: rgba(#fff, .2);
                position: absolute;
                top: 1px;
                left: 0;
            }
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: rgba(#fff, .2);
                position: absolute;
                bottom: 1px;
                left: 0;
            }

            ul {
                &:nth-of-type(1) {
                    @include grid-media($mobile, $tablet) {
                        display: none;
                        order: 2;
                        flex-wrap: wrap;
                        width: 100%;
                    }
                    li {
                        @include grid-media($mobile, $tablet) {
                            width: 100%;
                            // margin: 2px 0;
                        }
    
                        ul {
    
                            box-shadow: 0 3px 30px 0px rgba(#000, .1);
    
                            @include grid-media($mobile, $tablet) {
                                box-shadow: inset 0 3px 30px 0 rgba(#000, .1);
                            }
                        }
                    }
                }

                &:nth-of-type(2) {
                    @include grid-media($mobile, $tablet) {
                        width: calc(100% - 56px);
                        order: 3;
                        justify-content: flex-end;
                        align-items: flex-start;
                    }
                    li {
                        @include grid-media($mobile, $tablet) {
                            // width: 100%;
                            // margin: 2px 0;
                            height: 54px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
    
                        ul {
                            display: block;
                            position: absolute;
    
                            box-shadow: 0 3px 30px 0px rgba(#000, .1);
    
                            @include grid-media($mobile, $tablet) {
                                box-shadow: inset 0 3px 30px 0 rgba(#000, .1);
                            }
                        }

                        .button--navigation--lang {
                            & + ul {
                                width: calc(100% - 20px);
                            }
                        }
                    }
                }
            }
        }

        &.active {
            height: 100vh;

            ul {
                &, &:nth-of-type(1), &:nth-of-type(2) {
                    display: block;
                    width: 100%;
                }
            }
        }

    }

}


.list-game {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    width: 100%;
    // height: 100%;
    position: absolute;
    top: -467px; // 467 = 145 * 3 + 16 * 2
    padding: 32px 40px 24px;
    background: rgba(#111, .8);
    transition: all .2s;
    z-index: 4; 



    &:hover, &.active {
        transform: translate3d(0, calc(467px + 80px), 0);

        @include grid-media($mobile, $tablet, $desktophd) {
            transform: translate3d(0, calc(467px + 54px), 0);
        }
    }

    .grid-game {
        display: flex;
        justify-content: center;
        margin: 0 25px;

        @include grid-media($mobile, $tablet) {
            margin: 0 0px;
        }
    }

    // .swiper-slide {
    //     display: flex;
    //     justify-content: center;
    // }

    // .swiper-button {
    //     &-next, &-prev {
    //         &-listGame {
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;
    //             width: 40px;
    //             height: 80px;
    //             top: calc(50% - 40px / 2);

    //             background: rgba(#fff, .75);
    //             transition: background-color .2s $ani-easeInCubic;

    //             @include grid-media($mobile, $tablet) {
    //                 height: 40px;
    //             }

    //             &:before, &:after {
    //                 display: none;
    //             }

    //             &:hover {
    //                 background: rgba(#fff, 1);
    //             }

    //             .icon {
    //                 svg {
    //                     transform-origin: center center;
    //                     width: 14px;
    //                     height: 14px;
    //                 }
    //             }
    //         }
    //     }
    //     &-next {
    //         &-listGame {
    //             right: 0;
    //         }
    //     }
    //     &-prev {
    //         &-listGame {
    //             left: 0;
    //             .icon {
    //                 svg {
    //                     transform: scaleX(-1);
    //                 }
    //             }
    //         }
    //     }
    // }


    &-side {
        width: 156px;
        height: 100vh;
        top: 0;
        left: -156px;
        padding: 16px 0;
        box-sizing: border-box;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        &:hover {
            transform: translate3d(0, 0, 0);
        } 

        &.active, &.active:hover {
            transform: translate3d(156px, 0, 0);
        }



        &:before, &:after {
            content: "";
            display: block;
            width: 156px;
            height: 40px;
            @include grid-media($tablet, $mobile) {
                height: 70px;
            }
            position: absolute;
            pointer-events: none;
            left: 0;
            z-index: 9;
            background: linear-gradient(0deg, rgba(#111,0) 0%, rgba(#111, .95) 100%);
        }

        &:before {
            top: 120px;
        }

        &:after {
            bottom: 80px;
            transform: scaleY(-1);
        }
        
        
    }

    &__list {
        position: relative;
        width: 100%;
        height: calc(100vh - 200px);
        overflow: auto;
        padding: 20px 0;



        &::-webkit-scrollbar {
            width: 4px;
            transition: width .2s;

        }

        &:hover {
            &::-webkit-scrollbar {
                width: 8px;
    
            }
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: rgba(#FFF, 0); 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(#fff, .2); 
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(#fff, .3); 
        }

        .game__icon {
            margin: 0 auto 20px; 
        }
    }

    &__cs {
        display: flex;
        width: 100%;
        justify-content: center;
        .button {
            margin: 0 4px;
        }
    }

    &__toolbox {
        position: absolute;
        left: 100%;
        top: 0;

        .hamburger {
            background: rgba(#111, .95);
        }

    }
}


.username {
    font-weight: bold;
}
@import "variables-esports"; 


.dndbracket {
	position: relative;
	margin: 0 auto 0 0;
	overflow-x: auto;
	// padding: 30px;
	cursor: grab;

	// max-width: 100%;
	padding-bottom: 50px;
}

.dndbracket-rounds {
	padding-left: 20px;
	&.default {
		$def_matchwidth : 200px;
		$def_roundspace : 40px;
		.round {
			float: left;
			position: relative;
			width: $def_matchwidth;
			margin-right: $def_roundspace;

			//---- TITLES

			&__title {
				width: $def_matchwidth;
				padding: 3px 10px;
				background: #ddd;
				box-sizing: border-box;
				margin: 10px;
			} 
			
			// Using for make sprite title for each round
			// @for $i from 1 through $number_of_round {
			// 	&__round_#{$i}__title {
					
			// 	}
			// }
			
			//---- MATCHES

			&__matches {
				position: relative;
				&__match {
					&__inner {
						box-sizing: border-box;

					}
				}
				.match {
					width: 100%;
					height: 91px;
					margin: 10px;
					position: relative;
					&__inner {
						width: 200px;
						border: thin solid #bbb;
						height: 91px;
						background: #fff;
						.match_number {
							position: absolute;
							padding: 5px;
							font-size: 0.8em;
							width: 16px;
							top: 11px;
							left: -22px;
							text-align: center;
						}
						.date {
							padding: 3px;
							line-height: 23px;
							font-size: 0.8em;
						}
						.competitors {
							&__team {
								.team {
									&__inner {
										line-height: 23px;
										height: 23px;
										padding-left: 3px;
										margin: 3px; 
										border: 1px dashed #bbbbbb; 
										background: #ffffff;
										.score {
											float: right;
											background: #fafafa;
											width: 30px;
											text-align: center;
										}
										&.active {
											background: #ddffff;
											.score {
												float: right;
												background: #fafafa;
												width: 30px;
												text-align: center; 
											}
										}
									}
								}
							}
						}
						&--null {
							border: thin solid #e9e9e9;
							height: 91px;
							background: #fff; 
							color: #efefef;
							display: none; 
							
							.match_number {}
							.date {}
							.competitors {
								&__team {
									.team {
										&__inner {
											.score {}
										}
									}
								}
							}
						}
					}
				}
			}

			// Using for make sprite matches for each round
			// @for $i from 1 through $number_of_round {
			// 	&__round_#{$i}__matches {

			// 		&__match {

			// 		}

			// 	}
			// }
		}
	}
}

.dndbracket-rounds-loser {
	padding-left: 20px;
	position: relative;
	clear: both;
	overflow: hidden;
	&:after {
		clear: both;
		content: "";
		display: table;
	}

	&.default {
		$def_matchwidth : 200px;
		$def_roundspace : 40px;
		.round__loser {
			float: left;
			position: relative;
			width: $def_matchwidth;
			margin-right: $def_roundspace;

			//---- TITLES

			&__title {
				width: $def_matchwidth;
				padding: 3px 10px;
				background: #ddd;
				box-sizing: border-box;
				margin: 10px;
			}
			
			// Using for make sprite title for each round
			// @for $i from 1 through $number_of_round {
			// 	&__round_#{$i}__title {
					
			// 	}
			// }
			
			//---- MATCHES

			&__matches {
				position: relative;
				&__match {
					&__inner {
						box-sizing: border-box;

					}
				}
				.match__loser {
					width: 100%;
					height: 91px;
					margin: 10px;
					position: relative;
					&__inner {
						width: 200px;
						border: thin solid #bbb;
						height: 91px;
						background: #fff;
						.match_number {
							position: absolute;
							padding: 5px;
							font-size: 0.8em;
							width: 16px;
							top: 11px;
							left: -22px;
							text-align: center;
						}
						.date {
							padding: 3px;
							line-height: 23px;
							font-size: 0.8em;
						}
						.competitors {
							&__team {
								.team {
									&__inner {
										line-height: 23px;
										height: 23px;
										padding-left: 3px;
										margin: 3px; 
										border: 1px dashed #bbbbbb; 
										background: #ffffff;
										.score {
											float: right;
											background: #fafafa;
											width: 30px;
											text-align: center;
										}
										&.active {
											background: #ddffff;
											.score {
												float: right;
												background: #fafafa;
												width: 30px;
												text-align: center; 
											}
										}
									}
								}
							}
						}
						&--null {
							border: thin solid #e9e9e9;
							height: 91px;
							background: #fff; 
							color: #efefef;
							// display: none; 
							
							.match_number {}
							.date {}
							.competitors {
								&__team {
									.team {
										&__inner {
											.score {}
										}
									}
								}
							}
						}
					}
				}
			}

			// Using for make sprite matches for each round
			// @for $i from 1 through $number_of_round {
			// 	&__round_#{$i}__matches {

			// 		&__match {

			// 		}

			// 	}
			// }
		}
	}
}

.dndbracket-rounds-roundrobin {
	padding-left: 20px;
	&.default {
		$def_roundwidth : 100%;
		$def_matchwidth : 200px;
		$def_roundspace : 40px;
		.round__roundrobin {
			float: left;
			position: relative;
			width: $def_roundwidth;
			margin-right: $def_roundspace;

			//---- TITLES

			&__title {
				width: $def_matchwidth;
				padding: 3px 10px;
				background: #ddd;
				box-sizing: border-box;
				margin: 10px;
			}
			
			// Using for make sprite title for each round
			// @for $i from 1 through $number_of_round {
			// 	&__round_#{$i}__title {
					
			// 	}
			// }
			
			//---- MATCHES

			&__matches {
				position: relative;
				&__match {
					&__inner {
						box-sizing: border-box;

					}
				}
				.match__roundrobin {
					// width: 100%;
					height: 91px;
					margin: 10px;
					position: relative;
					float: left;
					&__inner {
						width: 200px;
						border: thin solid #bbb;
						height: 91px;
						background: #fff;
						.match_number {
							position: absolute;
							padding: 5px;
							font-size: 0.8em;
							width: 16px;
							top: 11px;
							left: -22px;
							text-align: center;
						}
						.date {
							padding: 3px;
							line-height: 23px;
							font-size: 0.8em;
						}
						.competitors {
							&__team {
								.team {
									&__inner {
										line-height: 23px;
										height: 23px;
										padding-left: 3px;
										margin: 3px; 
										border: 1px dashed #bbbbbb; 
										background: #ffffff;
										.score {
											float: right;
											background: #fafafa;
											width: 30px;
											text-align: center;
										}
										&.active {
											background: #ddffff;
											.score {
												float: right;
												background: #fafafa;
												width: 30px;
												text-align: center; 
											}
										}
									}
								}
							}
						}
						&--null {
							border: thin solid #e9e9e9;
							height: 91px;
							background: #fff; 
							color: #efefef;
							// display: none; 
							
							.match_number {}
							.date {}
							.competitors {
								&__team {
									.team {
										&__inner {
											.score {}
										}
									}
								}
							}
						}
					}
				}
			}

			// Using for make sprite matches for each round
			// @for $i from 1 through $number_of_round {
			// 	&__round_#{$i}__matches {

			// 		&__match {

			// 		}

			// 	}
			// }
		}
	}
}

.dndbracket-connections {
	position: absolute;
	top: 0;
	z-index: 0;
	&.default {
		.prev-a {
			position: absolute;
			border-top: 2px solid #111;
			border-right: 2px solid #111;
			border-bottom: none;
			border-left: none;
		}
		.prev-b {
			position: absolute;
			border-top: none;
			border-right: 2px solid #111;
			border-bottom: 2px solid #111;
			border-left: none;
		}
		.next {
			position: absolute;
			border-top: none;
			border-right: none;
			border-bottom: 2px solid #111;
			border-left: none;
		}
	}
}
.hero {
	overflow: hidden;
	&__item {
		position: absolute;
		img {
			object-fit: contain;
		}
		&.logo {
			width: auto;
			height: 7%;
			top: 4%;
			left: 50%;
			transform: translateX(-50%);
		}
		&.ghost {
			width: auto;
			height: 84%;
			bottom: 0;
			left: calc(50% - 825px / 2 - 144px);
		}
		&.phantom {
			width: auto;
			height: 63.24%;
			bottom: 0;
			right: calc(50% - 853px / 2 - 144px);
		}
		&.br-title {
			width: auto;
			height: 31%;
			bottom: 46%;
			right: calc(50% - 853px / 2 - 515px);

			@media (max-width: 1440px) {
				right: calc(50% - 825px / 2 - 425px);
			}
		}
		&.mp-title {
			width: auto;
			height: 31%;
			bottom: 17%;
			left: calc(50% - 825px / 2 - 550px);

			@media (max-width: 1440px) {
				left: calc(50% - 825px / 2 - 465px);
			}
		}
	}

	&__btns {
		display: flex;
		position: absolute;
		bottom: 44%;
		width: 900px;
		max-width: 90%;
		justify-content: space-between;

		.button {
			cursor: pointer;
		}
	}

	&__background {
		overflow: hidden;
	}
}

.section--index {
	& + .footer {
		position: absolute;
		bottom: 0;
		height: 300px;
		display: flex;
		justify-content: center;
		align-items: flex-end;

		background: linear-gradient(360deg, #19212D 15%, rgba(25, 33, 45, 0) 100%);

		.footer__content {
			height: auto;
		}
	}
}

.hero {
    &__glitch {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: all;

        .glitch {


            --gap-horizontal: 20px;
            --gap-vertical: 2px;
            --time-anim: 2.25s;
            --blend-mode-1: none;
            --blend-mode-2: hard-light;
            --blend-mode-3: hard-light;
            --blend-color-1: transparent;
            --blend-color-2: #0ffbf9;
            --blend-color-3: #cc0f39;


            position: absolute;
            top: calc(-1 * var(--gap-vertical));
            left: calc(-1 * var(--gap-horizontal));
            width: calc(100% + var(--gap-horizontal) * 2);
            height: calc(100% + var(--gap-vertical) * 2);
            background-repeat: no-repeat;
            background-position: 50% 0;
            background-color: var(--blend-color-1);
            background-size: cover;
            background-blend-mode: var(--blend-mode-1);
            // animation: glitch-anim-1-horizontal 1s infinite;

            &:nth-child(2) {
                background-color: var(--blend-color-2);
                background-blend-mode: var(--blend-mode-2);
                height: 100%;
            }
            &:nth-child(3) {
                background-color: var(--blend-color-3);
                background-blend-mode: var(--blend-mode-3);
                height: 100%;
            }
            &:nth-child(n+2) {
                opacity: 0;
            }
        }


        &:hover {
            .glitch {
                &:nth-child(n+2) {
                    opacity: 1;
                }
                &:nth-child(2) {
                    opacity: .3;
                    transform: translate3d(var(--gap-horizontal),0,0);
                    animation: glitch-anim-4-horizontal var(--time-anim) infinite linear alternate;
                }
                &:nth-child(3) {
                    opacity: .3;
                    transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
                    animation: glitch-anim-4-horizontal var(--time-anim) infinite linear alternate;
                }
            }
        }

    }
}

/* Horizontal */
@keyframes glitch-anim-1-horizontal {
	0% { 
		-webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
		clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
	}
	10% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
		clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
	}
	20% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
		clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
	}
	30% {
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
		clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
	}
	40% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	50% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	60% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	70% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	80% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	90% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	100% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
}

@keyframes glitch-anim-2-horizontal {
	0% { 
		-webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
		clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
	}
	15% {
		-webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
		clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
	}
	22% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
		clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
	}
	31% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	45% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
		clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
	}
	51% {
		-webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
		clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
	}
	63% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	76% {
		-webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
		clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
	}
	81% {
		-webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
		clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
	}
	94% {
		-webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
		clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
	}
	100% {
		-webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
		clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
	}
}

@keyframes glitch-anim-3-horizontal {
	0% { 
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
		clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
	}
	5% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
		clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
	}
	10% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
		clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
	}
	25% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	27% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
		clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
	}
	30% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
		clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
	}
	33% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
		clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
	}
	37% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
		clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
	}
	40% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	45% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
		clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
	}
	50% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
		clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
	}
	53% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
		clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
	}
	57% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
		clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
	}
	60% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
		clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
	}
	65% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
		clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
	}
	70% {
		-webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
		clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
	}
	73% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	80% {
		-webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
		clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
	}
	100% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
		clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
	}
}

@keyframes glitch-anim-4-horizontal {
	0% { 
		transform: translate3d(-20px,0,0)
	}
	5% {
		transform: translate3d(2px,0,0)
	}
	10% {
		transform: translate3d(10px,0,0)
	}
	25% {
		transform: translate3d(-10px,10px,0)
	}
	27% {
		transform: translate3d(4px,-5px,0)
	}
	30% {
		transform: translate3d(30px,10px,0)
	}
	33% {
		transform: translate3d(10px,-5px,0)
	}
	37% {
		transform: translate3d(12px,15px,0)
	}
	40% {
		transform: translate3d(-5px,10px,0)
	}
	45% {
		transform: translate3d(-20px,-5px,0)
	}
	50% {
		transform: translate3d(-10px,0,0)
	}
	53% {
		transform: translate3d(-2px,10px,0)
	}
	57% {
		transform: translate3d(-20px,0,0)
	}
	60% {
		transform: translate3d(-20px,0,0)
	}
	65% {
		transform: translate3d(-20px,0,0)
	}
	70% {
		transform: translate3d(-20px,0,0)
	}
	73% {
		transform: translate3d(-20px,0,0)
	}
	80% {
		transform: translate3d(-20px,0,0)
	}
	100% {
		transform: translate3d(-20px,0,0)
	}
}

/* Flash */
@keyframes glitch-anim-flash {
	0% { 
		opacity: 0.2; 
		transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
	}
	33%, 100% { 
		opacity: 0;
		transform: translate3d(0,0,0);
	}
}

@font-face {
    font-family: Roboto;
    src:    url('../fonts/Roboto/Roboto-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: Roboto;
    src:    url('../fonts/Roboto/Roboto-Regular.ttf');
    font-weight: normal;  
} 

body {
    font-family: Roboto, sans-serif; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;  
}
.overlay {
    @include layer_full;

    &--dark {
        background: linear-gradient(90deg, rgba(0, 0, 0, .95) 20%, rgba(0, 0, 0, 0.0));

        @include grid-media($mobile) {
            background: linear-gradient(135deg, rgba(0, 0, 0, .9) 20%, rgba(0, 0, 0, .2));    
        }
    }
    &--gradient1 {
        background: $gradient1;
    }
    &--gradient2 {
        background: $gradient2;
    }
}


.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none; 
    z-index: 2;

    &:hover {
        cursor: pointer;
    }


    // size



    // style 

    &--default {
        background: $base-normal;
        padding: 13px 16px;
        .label {
            font-family: $font-secondary;
            color: $black;
            font-weight: bold; 
            font-stretch: condensed;
            font-size: 18px;
        } 
    }

    &--default-mini {
        background: rgba($base-normal, .1);
        padding: 8px 8px;
        .label {
            font-family: $font-secondary;
            color: $black;
            font-size: 14px;
        } 
    }

    &--detail {
        background: transparent;
        height: 60px;

        .label {
            text-transform: uppercase;
            font-size: 16px;
            line-height: 18px;
            color: $white;
            font-weight: bold;


            @include grid-media($desktop-md) {
                font-size: 18px;
                line-height: 26px;
            }

            @include grid-media($desktop-lg) {
                font-size: 20px;
                line-height: 28px;
            }

            @include grid-media($desktop-xl, $desktop-xxl) {
                font-size: 24px;
                line-height: 32px;
            }


        }

        .icon[data-icon$="arrow"] {
            display: block;
            width: 7px;
            height: 16px;
            margin-left: 13px;
            margin-top: 2px;
        }

        &:hover {
            .icon {
                margin-left: 15px;
            }
        }
    }

    &--playnow {
        background: rgba($white, .9);
        height: 50px;
        padding: 0 16px 0 12px;
        border-radius: 99px;
        transition: all .1s $ani-easeInCubic;

        @include grid-media($desktop-md) {
            height: 50px;
            padding: 0 20px 0 16px;
        }

        @include grid-media($desktop-lg) {
            height: 50px;
            padding: 0 28px 0 22px;
        }

        @include grid-media($desktop-xl, $desktop-xxl) {
            height: 60px;
            padding: 0 36px 0 30px;
        }

        .label {
            text-transform: uppercase;
            font-size: 16px;
            line-height: 26px;
            color: $black;
            font-weight: bold;
            transition: all .05s $ani-easeInCubic;


            @include grid-media($desktop-md) {
                font-size: 18px;
                line-height: 26px;
            }

            @include grid-media($desktop-lg) {
                font-size: 20px;
                line-height: 32px;
            }

            @include grid-media($desktop-xl, $desktop-xxl) {
                font-size: 24px;
                line-height: 36px;
            }

        }

        .icon[data-icon$="play"], .icon[data-icon$="download"] {
            display: block;
            width: 24px;
            height: 24px;
            margin-right: 6px;
            svg {
                .toBase, .toWhite {
                    transition: all .02s $ani-easeInCubic;
                }
            }
        }

        &:hover {
            // background: rgba($white, 1);
            background: $base-normal;
            .label {
                color: $white;
            }
            .icon {
                svg {
                    // circle {
                    //     fill: $white;
                    // }
                    // path {
                    //     fill: $base-normal;
                    // }
                    .toWhite {
                        fill: $white;
                    }
                    .toBase {
                        fill: $base-normal;
                    }
                }
            }
        }
    }

    &--download {
        background: transparent;
        width: 168px;
        height: 50px;


        @include grid-media($desktop-md) {
            width: calc(168px * 1.2);
            height: calc(50px * 1.2);
        }

        @include grid-media($desktop-lg) {
            width: calc(168px * 1.3);
            height: calc(50px * 1.3);
        }

        @include grid-media($desktop-xl, $desktop-xxl) {
            width: calc(168px * 1.4);
            height: calc(50px * 1.4);
        }

        .icon[data-icon^="download"] {
            display: block;
            width: 168px;
            height: 50px;
            margin-right: 6px;

            @include grid-media($desktop-md) {
                width: calc(168px * 1.2);
                height: calc(50px * 1).2;
            }
    
            @include grid-media($desktop-lg) {
                width: calc(168px * 1.4);
                height: calc(50px * 1.4);
            }
    
            @include grid-media($desktop-xl, $desktop-xxl) {
                width: calc(168px * 1.6);
                height: calc(50px * 1.6);
            }
        }

        &:hover {
            filter: brightness(120%);
        }
    }

    &--cs {
        width: 46px;
        height: 46px;
        .icon {
            display: block;
            width: 46px;
            height: 46px;
        }
    }

    &--loadmore {
        display: block;
        width: 140px;
        border-radius: 12px;
        margin: 0 auto;
        border: 1px solid #979797;
        background-color: #fff;
        padding: 8px;
        text-decoration: none;
        text-align: center;

        .label {
            color: #474747;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }


        &:hover {
            border: 1px solid #111;
            .label {
                color: #222;
            }
        }
    }


    &--toolbox {
        background: rgba(17, 17, 17, 0.95);
        width: 44px;
        height: 45px;
        .icon {
            display: block;
            width: 25px;
            height: 25px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &:hover {
            opacity: .7;
        }
    }

    &--navigation {
        background: transparent;
        height: 80px;
        padding: 0 12px;

        @include grid-media($mobile, $tablet, $desktophd) {
            height: 54px;
            width: 100% !important; 
        }


        .label {
            text-transform: uppercase;
            font-size: 16px;
            line-height: 18px;
            color: $black;

            @include grid-media($desktop-lg) {
                font-size: 18px;
                line-height: 24px;
            }

            @include grid-media($desktop-xl, $desktop-xxl) {
                font-size: 20px;
                line-height: 32px;
            }

        }

        .icon[data-icon$="arrow"] {
            display: block;
            width: 7px;
            transform: rotate(90deg);
            height: 16px;
            margin-left: 13px;
            margin-top: 2px;
        }

        .icon[data-icon^="lang"] {
            display: block;
            width: 42px;
            height: 28px;
            margin-top: -4px;

            @include grid-media($mobile, $tablet) {
                width: 28px;
                height: 14px;
            }
        }

        &:hover:not(.button--navigation--lang), &.active {
            // text-shadow: 0px 0px 1px rgba(#111, 1);
            // background: $base-normal;
            background: rgba($base-normal, 1);
            .label {
                color: $white;
                text-shadow: 0px 0px 1px rgba(#fff, 1);
            }
            .icon:not([data-icon^="lang"]) {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }


        &--sub {
            height: 42px;
            .icon[data-icon^="lang"] {
                display: block;
                width: 27px;
                height: 21px;
                margin-top: -4px;
            }
        }

        &--lang {
            // width: 100px;
            margin: 0 auto;
        }

        &.logo {
            &:hover {
                background: none;
            }
        }
    }
    

    &--tab {
        background: $gray-darkest;
        border: 1px solid $gray-normal;
        padding: 13px 44px;
        transition: all .2s $ani-easeInCubic;
        @include grid-media($mobile) {
            padding: 13px 20px;
        }
        .label {
            font-family: $font-primary;
            color: $white;
            font-size: 22px;
            font-weight: normal;
        } 
        
        &:hover, &.active {
            background: $base-normal;
            border: 1px solid $base-normal;
            .label {
                color: $black; 
                font-weight: bold;
            }
        }

        &.active {
            &:after {
                content: "";
                display: block;
                background: $base-normal;
                position: absolute;
                bottom: -6px;
                left: calc(50% - 12px / 2);
                width: 12px;
                height: 12px;
                transform: rotate(45deg);
            }
        }
    }

    &--login {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 13px 16px;
        border-radius: 8px;
        &--zingid {
            background: #0AA5E8;
            &:hover {
                background: lighten(#0AA5E8, 5%);
            }
        }
        &--facebook {
            background: #1776CA;
            &:hover {
                background: lighten(#1776CA, 5%);
            }
        }
        &--google {
            background: #DF4930; 
            &:hover {
                background: lighten(#DF4930, 5%);
            }
        }
        .label {
            font-family: $font-secondary;
            color: $white;
            font-weight: bold; 
            font-size: 16px;
        } 

        &:hover {
            cursor: pointer;
        }
    }

    &--nothing {
        .label {
            color: $white;
        }
    }

    &--foggy_in {
        background: transparent;
        padding: 25px 16px;
        @include grid-media($mobile) {
            padding: 22px 8px;
            text-align: center;
        }
        .label {
            font-family: $font-secondary;
            color: $white;
            font-weight: bold;
            font-stretch: condensed;
            font-size: 18px;
            text-transform: uppercase;
            @include grid-media($mobile) {
                font-size: 13px;
            }
        }

        .foggy_in {
            &__corner {
                opacity: 0;
                @include layer_full;
                pointer-events: none;

                &--top, &--bottom {
                    &:before, &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        border-style: solid;
                        border-width: 1px;
                    }
                }

                &--top {
                    &:before {
                        border-color: $base-normal transparent transparent $base-normal;
                        top: 5px;
                        left: 5px;
                    }
                    &:after {
                        border-color: $base-normal $base-normal transparent transparent;
                        top: 5px;
                        right: 5px;
                    }
                }

                &--bottom {
                    &:before {
                        border-color: transparent transparent $base-normal $base-normal;
                        bottom: 5px;
                        left: 5px;
                    }
                    &:after {
                        border-color: transparent $base-normal $base-normal transparent;
                        bottom: 5px;
                        right: 5px;
                    }
                }
            }
        }

        &:hover, &.active, &:focus {
            background: rgba($gray-darker, .45);
            .label {
                color: $base-normal;
            }
        }

        &.active, &:focus {
            .foggy_in {
                &__corner {
                    opacity: 1;
                }
            }
        }
    }

    &--foggy_out { 
        background: transparent;
        padding: 25px 16px;
        .label {
            font-family: $font-secondary;
            color: $white;
            font-weight: bold;
            font-stretch: condensed;
            font-size: 18px;
            text-transform: uppercase;
        }

        .foggy_out {
            &__corner {
                @include layer_full;
                opacity: 0;
                pointer-events: none;

                &--top, &--bottom {
                    &:before, &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        border-style: solid;
                        border-width: 1px;
                    }
                }

                &--top {
                    &:before {
                        border-color: $base-normal transparent transparent $base-normal;
                        top: -5px;
                        left: -5px;
                    }
                    &:after {
                        border-color: $base-normal $base-normal transparent transparent;
                        top: -5px;
                        right: -5px;
                    }
                }

                &--bottom {
                    &:before {
                        border-color: transparent transparent $base-normal $base-normal;
                        bottom: -5px;
                        left: -5px;
                    }
                    &:after {
                        border-color: transparent $base-normal $base-normal transparent;
                        bottom: -5px;
                        right: -5px;
                    }
                }
            }
        }

        &:hover, &.active, &:focus, &.swiper-pagination-bullet-active {
            background: rgba($gray-darker, .45);
            .label {
                color: $white;
            }
        }

        &.active, &:focus, &.swiper-pagination-bullet-active {
            .foggy_out {
                &__corner {
                    opacity: 1;
                }
            }
        }
    }

    &--dark_out {
        background: $gray-darker;
        padding: 13px 56px;
          .label {
              font-family: $font-secondary;
              color: #fff;
              font-weight: bold;
              font-stretch: condensed;
              font-size: 24px;
              text-transform: uppercase;
          }
  
          .dark_out {
              &__corner {
                  @include layer_full;
                  pointer-events: none;
  
                  &--top, &--bottom {
                      &:before, &:after {
                          content: "";
                          display: block;
                          position: absolute;
                          width: 6px;
                          height: 6px;
                          border-style: solid;
                          border-width: 1px;
                      }
                  }
  
                  &--top {
                      &:before {
                          border-color: $gray-normal transparent transparent $gray-normal;
                          top: -5px;
                          left: -5px;
                      }
                      &:after {
                          border-color: $gray-normal $gray-normal transparent transparent;
                          top: -5px;
                          right: -5px;
                      }
                  }
  
                  &--bottom {
                      &:before {
                          border-color: transparent transparent $gray-normal $gray-normal;
                          bottom: -5px;
                          left: -5px;
                      }
                      &:after {
                          border-color: transparent $gray-normal $gray-normal transparent;
                          bottom: -5px;
                          right: -5px;
                      }
                  }
              }
          }
    }

    &--light_out {
        background: $base-normal;
        padding: 10px 16px;

          .label {
              font-family: $font-secondary;
              color: #322626;
              font-weight: bold;
              font-stretch: condensed;
              font-size: 22px;
              text-transform: uppercase;
          }

          &.special {
            padding: 16px 38px;
            .label {
                font-size: 24px;
            }
          }
  
          .light_out {
              &__corner {
                  @include layer_full;
                  pointer-events: none;
  
                  &--top, &--bottom {
                      &:before, &:after {
                          content: "";
                          display: block;
                          position: absolute;
                          width: 6px;
                          height: 6px;
                          border-style: solid;
                          border-width: 1px;
                      }
                  }
  
                  &--top {
                      &:before {
                          border-color: $white transparent transparent $white;
                          top: -5px;
                          left: -5px;
                      }
                      &:after {
                          border-color: $white $white transparent transparent;
                          top: -5px;
                          right: -5px;
                      }
                  }
  
                  &--bottom {
                      &:before {
                          border-color: transparent transparent $white $white;
                          bottom: -5px;
                          left: -5px;
                      }
                      &:after {
                          border-color: transparent $white $white transparent;
                          bottom: -5px;
                          right: -5px;
                      }
                  }
              }
          }
    }

    &--nav_sub {
        width: 280px;
        height: 60px;
        background: transparent;
        display: flex;
        justify-content: start;
        padding: 0 12px;
        .icon {
            display: flex;
            align-items: center;
            height: 100%;
            margin-right: 12px;
            img {
                height: 80%;
                width: auto;
                // image-rendering: optimizeSpeed;             /*                     */
                // image-rendering: -moz-crisp-edges;          /* Firefox             */
                // image-rendering: -o-crisp-edges;            /* Opera               */
                // image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
                // image-rendering: pixelated;                 /* Chrome as of 2019   */
                // image-rendering: optimize-contrast;         /* CSS3 Proposed       */
                // -ms-interpolation-mode: nearest-neighbor;   /* IE8+                */
            }
        }
        .label {
            height: 100%;
            font-family: $font-secondary;
            color: $white;
            font-weight: bold; 
            font-stretch: condensed;
            display: flex;
            align-items: center;
            font-size: 16px;
            
        }  

        &:hover {
            background: $base-normal;
            .label {
                color: $black;
            }
        }
    }
}
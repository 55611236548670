.news {
    z-index: 2;

    &__list {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
    }

    &__single {
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        width: 265px;
        height: 400px;
        max-width: 100%;
        background: #fafafa; 
        margin: 15px 10px;
        border-radius: 24px;
        overflow: hidden;

        &:first-child {
            width: 550px;
            height: 400px;
        }

        @include grid-media($mobile, $tablet) {
            width: 100%; 
        }

        a {
            text-decoration: none;
        }

        &__thumbnail {
            width: 100%;
            display: block;
            position: relative;
            height: 180px;
            overflow: hidden;

            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 70%;
                bottom: 0;
                left: 0;
                pointer-events: none;
                background: linear-gradient(180deg, rgba(#000, 0) 0%, rgba(#000, .65) 100%);
            }

            @include grid-media($mobile, $tablet) {
                width: 100px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .2s;
            }

            .overlay--dark {
                opacity: 1;
                transition: opacity .2s $ani-easeInSine;
            }
        }

        &:first-child {
            .news__single__thumbnail {
                width: 100%;
                height: 100%; 
            }
        }

        &:hover { 
            .news__single__thumbnail {
                img {
                    transform: scale(1.1);
                }
            }
        }

        &__detail {
            height: calc(100% - 180px);
            width: 100%;
            padding: 16px;

        }

        &:first-child {
            .news__single__detail {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 35%;

            }
        }
        &__time {
            color: rgba($black, .5);
            font-size: 14px;
            line-height: 18px;
        }
        &:first-child {
            .news__single__time {
                color: rgba(#fff, .5);
            }
        }
        &__title {
            margin-top: 0px;
            color: rgba($black, 1);
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
        }
        &:first-child {
            .news__single__title {
                color: rgba(#fff, 1);
            }
        }
        &__shortcontent {
            margin-top: 12px;
            color: rgba($black, .5);
            font-size: 18px;
            line-height: 22px;
        }
        &__button {
            display: flex;
            justify-content: flex-start;
            text-decoration: none;
            position: absolute;
            bottom: 16px;
            left: 0px;
            padding: 12px;
            @include grid-media($mobile, $tablet) {
                left: 110px;
            }
            .label {
                font-weight: bold;
                color: $base-darker;
                font-size: 14px;
                margin-right: 6px;
            }
            .icon {
                display: block;
                width: 7px;
                height: 16px;
                // margin-top: 2px;
                
                svg {
                    width: 100%;
                    height: 100%;

                    path {
                      fill: $base-darker;  
                    }
                }
            }
        }

        &:first-child {
            .news__single__button {
                background: #FFF;
                padding: 6px;
                border-radius: 6px;
                left: 16px;
            }
        }
        &__button--social {
            display: inline-flex;
            justify-content: flex-start;
            text-decoration: none;
            position: static;
            padding: 4px;
            .label {
                font-weight: bold;
                color: $base-darker;
                font-size: 14px;
                margin-right: 6px;
            }
            .icon {
                display: block;
                width: 20px;
                height: 20px;
                margin-top: 2px;
                
                svg {
                    width: 100%;
                    height: 100%;

                    path {
                    //   fill: $base-darker;  
                    }
                }
            }
        }

        &:first-child {
            .news__single__button--social {
                background: #FFF;
                padding: 6px;
                border-radius: 6px;
            }
        }


        &__share {
            position: absolute;
            right: 12px;
            bottom: 8px;
        }

        &:hover, &.active {

            &.news__single {
                .overlay--dark {
                    opacity: 0;
                }
            }
            .news__single__time {
                opacity: 1;
            }
            .news__single__title {
                opacity: 1; 
            }
            .news__single__shortcontent {
                opacity: 1;
            }
        }
    }

    &__single-sub {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 550px;
        height: 100px;
        max-width: 100%;
        background: #fafafa; 
        margin: 15px 10px;
        border-radius: 24px;
        overflow: hidden;

        @include grid-media($mobile, $tablet) {
            width: 100%; 
        }

        a {
            text-decoration: none;
        }

        &__thumbnail {
            width: 100px;
            display: block;
            position: relative;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .overlay--dark {
                opacity: 1;
                transition: opacity .2s $ani-easeInSine;
            }
        }

        &__detail {
            width: calc(100% - 100px - 16px);

        }
        &__time {
            margin-top: 8px;
            color: rgba($black, .5);
            font-size: 14px;
            line-height: 18px;
        }
        &__title {
            margin-top: 0px;
            color: rgba($black, 1);
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
        }
        &__shortcontent {
            margin-top: 12px;
            color: rgba($black, .5);
            font-size: 18px;
            line-height: 22px;
        }
        &__button {
            display: flex;
            justify-content: flex-start;
            text-decoration: none;
            position: absolute;
            bottom: 0;
            left: 190px;
            padding: 12px;
            @include grid-media($mobile, $tablet) {
                left: 110px;
            }
            .label {
                font-weight: bold;
                color: $base-darker;
                font-size: 14px;
                margin-right: 6px;
            }
            .icon {
                display: block;
                width: 7px;
                height: 16px;
                // margin-top: 2px;
                
                svg {
                    width: 100%;
                    height: 100%;

                    path {
                      fill: $base-darker;  
                    }
                }
            }
        }
        &__button--social {
            display: inline-flex;
            justify-content: flex-start;
            text-decoration: none;
            position: static;
            padding: 4px;
            .label {
                font-weight: bold;
                color: $base-darker;
                font-size: 14px;
                margin-right: 6px;
            }
            .icon {
                display: block;
                width: 20px;
                height: 20px;
                margin-top: 2px;
                
                svg {
                    width: 100%;
                    height: 100%;

                    path {
                    //   fill: $base-darker;  
                    }
                }
            }
        }


        &__share {
            position: absolute;
            right: 12px;
            bottom: 8px;
        }

        &:hover, &.active {

            &.news__single {
                .overlay--dark {
                    opacity: 0;
                }
            }
            .news__single__time {
                opacity: 1;
            }
            .news__single__title {
                opacity: 1; 
            }
            .news__single__shortcontent {
                opacity: 1;
            }
        }
    }


    &__content {
        position: relative;
        z-index: 2;
        background: #fafafa;
        padding: 20px;
        margin-top: -100px;
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100px;
            box-shadow: 0 -40px 30px 0 rgba(0, 0, 0, 0.3);
            top: 0;
            left: 0;
            z-index: -1;

            @include grid-media($mobile) {
                display: none;
            }
        }

        @include grid-media($mobile, $tablet) {
            background: transparent;
            margin-top: 40px;
        }

        &__breadcrumbs {
            ul {
                display: flex;
                justify-content: flex-start;
                li {
                    margin-right: 4px;
                    a {
                        text-decoration: none;
                        .label {
                            font-size: 12px;
                            line-height: 16px;
                            font-weight: bold;
                            color: $gray-darkest;
                        }
                        .icon {
                            display: inline-block;
                            vertical-align: middle;
                            width: 6px;
                            height: 16px;
                            svg {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                path {
                                    fill: $black;
                                }

                            }
                        }
                    }
                }
            }
        }

        &__title {
            .label {
                font-weight: bold;
                font-size: 30px;
                line-height: 38px;
            }
        }

        &__meta {
            .time {
                color: $gray-lighter;
                line-height: 16px;
                font-size: 14px;
                vertical-align: text-top;
                margin-right: 16px;
            }
        }
    }

    .swiper-container-newsList {
        .swiper-wrapper {
            transition-duration: .5s;
            transition-timing-function: $ani-easeInOutBack;
            .swiper-slide {
                display: flex;
                justify-content: center;
            }
        }
        .swiper-button {
            &-next, &-prev {
                &-newsList {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 80px;

                    background: rgba(#fff, .75);
                    transition: background-color .2s $ani-easeInCubic;

                    @include grid-media($mobile) {
                        height: 40px;
                    }

                    &:before, &:after {
                        display: none;
                    }

                    &:hover {
                        background: rgba(#fff, 1);
                    }

                    .icon {
                        svg {
                            transform-origin: center center;
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }
            &-next {
                &-newsList {
                    right: 0;
                }
            }
            &-prev {
                &-newsList {
                    left: 0;
                    .icon {
                        svg {
                            transform: scaleX(-1);
                        }
                    }
                }
            }
        }
    }
}
@import "css-reset-and-normalize";
@import "bourbon-neat";
$hamburger-padding-x        : 8px;
$hamburger-padding-y        : 11px;
$hamburger-layer-width      : 28px;
$hamburger-layer-height     : 4px;
$hamburger-layer-spacing    : 5px;
$hamburger-layer-color      : rgb(190, 190, 190);
@import "hamburgers/_sass/hamburgers/hamburgers";

@import "configs/design-system";
@import "configs/fonts"; 

@import "configs/mixins/grid";
@import "configs/mixins/layer";
@import "configs/mixins/border_bevel";

@import "configs/support";
@import "configs/layout"; 
@import "configs/article"; 

@import "elements/overlay"; 
@import "elements/button"; 
@import "elements/title"; 
@import "elements/lightbox"; 
@import "elements/news"; 
@import "elements/tournament"; 
@import "elements/general"; 
@import "elements/pagination"; 
@import "elements/accordion"; 
@import "elements/tab"; 
@import "elements/hero"; 
@import "elements/game"; 
@import "elements/popup_login"; 
@import "elements/heroCarousel"; 
 



@import "components/loading";

// TOURNAMENT
 
@import "tournament/tournament";
@import "tournament/tournament-template-codmt";  


// CUSTOM 

// @import "components/tournament_selector";
@import "components/navigation"; 
@import "components/header"; 
@import "components/content"; 
@import "components/footer"; 
@import "components/popup"; 
@import "components/form"; 

.background {
    &-base {
        background: $base-normal;
        &-darker {
            background: $base-darker;
        }
        &-darkest {
            background: $base-darkest;
        }
        &-lighter {
            background: $base-lighter;
        }
        &-lightest {
            background: $base-lightest;
        }
    }

    // &-base2 {
    //     background: $base2-normal;
    //     &-darker {
    //         background: $base2-darker;
    //     }
    //     &-darkest {
    //         background: $base2-darkest;
    //     }
    //     &-lighter {
    //         background: $base2-lighter;
    //     }
    //     &-lightest {
    //         background: $base2-lightest;
    //     }
    // }

    // &-base3 {
    //     background: $base3-normal;
    //     &-darker {
    //         background: $base3-darker;
    //     }
    //     &-darkest {
    //         background: $base3-darkest;
    //     }
    //     &-lighter {
    //         background: $base3-lighter;
    //     }
    //     &-lightest {
    //         background: $base3-lightest;
    //     }
    // }

    &-white {
        background: $white;
    }
    &-black {
        background: $black;
    }
}

.color {
    &-base {
        color: $base-normal;
        &-darker {
            color: $base-darker;
        }
        &-darkest {
            color: $base-darkest;
        }
        &-lighter {
            color: $base-lighter;
        }
        &-lightest { 
            color: $base-lightest;
        }
    }

    // &-base2 {
    //     color: $base2-normal;
    //     &-darker {
    //         color: $base2-darker;
    //     }
    //     &-darkest {
    //         color: $base2-darkest;
    //     }
    //     &-lighter {
    //         color: $base2-lighter;
    //     }
    //     &-lightest {
    //         color: $base2-lightest;
    //     }
    // }

    // &-base3 {
    //     color: $base3-normal;
    //     &-darker {
    //         color: $base3-darker;
    //     }
    //     &-darkest {
    //         color: $base3-darkest;
    //     }
    //     &-lighter {
    //         color: $base3-lighter;
    //     }
    //     &-lightest {
    //         color: $base3-lightest;
    //     }
    // }

    &-white {
        color: $white;
    }
    &-black {
        color: $black;
    }
}

// VISIBLE

.visible {
    z-index: 2;
}

// ALIGN

.width {
    &--100p {
        width: 100%;
    }
    &--50p {
        width: 50%;
    }
    @for $i from 1 through 10 {
        &--#{$i} {
            margin-left: #{$i * 10}px;
            margin-right: #{$i * 10}px;
        }
    }
}

.margin-horizonal {
    @for $i from 1 through 10 {
        &--#{$i} {
            margin-left: #{$i * 10}px;
            margin-right: #{$i * 10}px;
        }
    }
}

.align {
    display: flex;
    width: 100%;
    &--center {
        justify-content: center;
    }
    &--left {
        justify-content: flex-start;
    }
    &--right {
        justify-content: flex-end;
    }
    &--middle {
        height: 100%;
        align-items: center;
        flex-wrap: wrap;
    }
    &--top {
        height: 100%;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    &--bottom {
        height: 100%;
        align-items: flex-end;
        flex-wrap: wrap;
    }
}

.space {
    width: 100%;
    display: block;

    @for $i from 1 through 10 {
        &--#{$i}0 {
            height: #{$i * 10}px;


            // @include grid-media($desktop-lg) {
            //     height: #{$i * 20}px;
            // }
    
            // @include grid-media($desktop-xl, $desktop-xxl) {
            //     height: #{$i * 25}px;
            // }
        }
    }
}

.placeholder {
    &--navigation {
        height: 68px;
    }
}

.popup-opened {
    overflow: hidden;
}